<template>
  <v-container class="mt-12 pt-12 wrap-sec">
    <div class="wrap-opt">
      <h1 class="mb-6">{{ title }}</h1>
      <v-row class="form-w px-6">
        <v-col class="my-0 pa-0 pr-2" cols="2">
          <div class="wrap-flag">
            <v-menu
              offset-y
              transition="slide-x-transition"
              left
              class=""
              bottom
              rounded="lg"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                  <img
                    :src="`${require(`@/Countries/` + Country.flag)}`"
                    alt=""
                    srcset=""
                  />
                  <!-- <p>+{{ Country.callingCodes[0] }}</p> -->
                </div>
              </template>
              <v-list height="250px" class="list">
                <v-list-item
                  v-for="(item, index) in Countries"
                  :key="index"
                  class="list-item"
                  @click="Country = item"
                >
                  <div class="img-text">
                    <img
                      :src="`${require(`@/Countries/` + item.flag)}`"
                      alt=""
                      srcset=""
                    />
                    <p class="ml-2">
                      <b>{{ item.name }} </b> +{{ item.callingCodes[0] }}
                    </p>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col class="my-0 pa-0" cols="10">
          <v-text-field
            :color="color"
            v-model="phone"
            height="52px"
            required
            @keydown="isNumber($event)"
            type="tel"
            :rules="phone == '' ? [] : phoneRule"
            class="rounded-lg"
            filled
            :disabled="btnLoading"
            rounded
            :prefix="`+${Country.callingCodes[0]}`"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn
            :color="color"
            class="color-btn"
            block
            :loading="btnLoading"
            style="border-radius: 15px; color: #fff"
            height="50px"
            @click="sendOtp"
          >
            Enviar</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CardTemplate from "@/components/cardTemplate";
import { mapActions, mapGetters } from "vuex";
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";
import axios from "axios";
export default {
  name: "tracking.",

  data: () => ({
    Loding: true,
    Countries: countries,
    Country: country,
    btnLoading: false,
    color: "null",
    title: "Ingresa tu número",
    phone: null,
    phoneRule: [
      (v) => /^[0-9]+$/.test(v) || "Campo numérico",
      (v) => !!v || "Requerido",
    ],
  }),
  components: {
    CardTemplate,
  },
  computed: {
    ...mapGetters(["CarShop", "cantOrder", "infoData", "CarShop"]),
  },
  methods: {
    ...mapActions(["Alert_", "addData_info", "_vipDataUser"]),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async sendOtp() {
      this.btnLoading = true;
      if (this.phone != null && this.phone != "") {
        var code = this.Country.callingCodes[0];

        let data = {
          businessId: this.infoData.id,
          phone: code + this.phone.replace(" ", ""),
        };

        await axios
          .post(
            `${process.env.VUE_APP_EL_OCHO}/httpMenuhnVerifyPhone`,
            data
          )
          .then((response) => {
            // console.debug(response.data);
            // console.debug(response.data.message);
            this.title = "Bienvenido";
            var obj = {
              phone: this.phone,
              name: response.data.name,
            };
            this._vipDataUser(obj);
            setTimeout(() => {
              this.btnLoading = false;
              this.$router.push({
                path: `/${this.$route.params._id}`,
              });
            }, 2000);
            // this.allCities = response.data;
          })
          .catch((error) => {
            this.btnLoading = false;
            // console.debug(error.response.data.message);
            this.Alert_({
              text: `😬 ${error.response.data.message}`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-alert",
              type: "error",
            });
          });
      } else {
        this.Alert_({
          text: "😬 Completa tu información",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-alert",
          type: "error",
        });

        this.btnLoading = false;
      }
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  mounted() {
    this.color = this.infoData.qrThemeColor;
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.wrap-opt {
  background-color: #f2f2f2;
  height: 50vh;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrap-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-w {
  height: 100px;
  flex: none !important;
}
.wrap-flag {
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-btn {
  color: #fff;
}
</style>
